<template lang="pug">
    div
        //Breadcrumb navigation
        div(style="color:grey")
            router-link(to="/clients") Clients
            span.mx-1 ›
            span(v-if="!isLoading")
                router-link(:to="'/clients/'+$route.params.client_id") {{clientName ? clientName : 'Client'}}
                span.mx-1 ›
                span Edit Client
        //router-link(:to="'/clients/'+$route.params.client_id") Back to Client
        div.d-flex.justify-content-between.align-items-center.mb-1(style="max-width: 1280px")
            h2.m-0 Edit Client
            div
                b-button.ml-2.my-1(v-b-modal.transfer-modal variant='outline-secondary' v-if="$store.state.allow_reassign_client" :disabled="isLoading || isSubmitting") 
                    b-icon.mr-2(icon="arrow-left-right")
                    | Transfer Client
                //b-button.ml-2.my-1(v-b-modal.delete-modal variant='danger' :disabled="isLoading || isSubmitting") 
                    b-icon.mr-2(icon="trash")
                    | Delete Client

        b-card.border-0(style="max-width: 1280px")
            b-overlay(:show="isLoading" variant="white")
                <b-alert variant="danger" :show="isError" ref="errorMsg">{{errorMessage}}</b-alert>
                b-form(@submit.prevent="editClient")
                    b-form-group(label="Name" label-for="name")
                        b-form-input(id="name" v-model="client.name" type="text" placeholder="Enter a name" required)
                    b-form-group(v-if="$store.state.enable_client_nickname" label="Nickname (Optional)" label-for="nickname")
                        b-form-input(id="nickname" v-model="client.nickname" type="text" placeholder="Enter a nickname")
                    b-form-group(label="Contact Number (Optional)" label-for="contact_number")
                        vue-tel-input(id="contact_number" v-model="client.contact_number" :inputOptions="{required:!$store.state.optional_client_contact_number,maxlength:25}")
                    b-form-group(label="Case ID (Optional)" label-for="case_id" v-if="!$store.state.no_case_id")
                        b-form-input(id="case_id" v-model="client.case_id" type="text" placeholder="Enter a case id")
                    b-form-group(:label="`${$.clientGroup}${$store.state.client_require_client_group ? '' : ' (Optional)'}`")
                        b-overlay(:show="loadingClientGroups" no-center rounded opacity="0" spinner-small)
                            template(#overlay)
                                div.d-flex.h-100.justify-content-end.p-2
                                    b-spinner(small)
                            v-select(multiple v-model="client.client_groups" @search="searchClientGroup" :options="clientGroupOptions" :filterable="false" :placeholder="client.client_groups.length ? '' : `Start typing to search for ${$.clientGroup}...`")
                                template(v-if="$store.state.client_require_client_group" #search="{attributes, events}")
                                    input(class="vs__search" :required="$store.state.client_require_client_group && (client.client_groups.length < 1)" v-bind="attributes" v-on="events")
                    b-form-group(label="Default Reminder Schedule (Optional)" label-for="reminder_schedule")
                        v-select.my-1(id="reminder_schedule" v-model="client.default_schedule" :options="reminderScheduleOptions" :reduce="reminderSchedule => reminderSchedule.content" label="label" )
                    b-form-group(label="Default Reminder Template (Optional)" label-for="reminder_template")
                        v-select.my-1(id="reminder_template" v-model="client.default_reminder_template" :options="reminderTemplateOptions" :reduce="reminderTemplate => reminderTemplate.content" label="name" )
                        b-alert.mt-1(variant="primary" :show="client.default_reminder_template != null" )
                            | <b>Preview: </b>
                            div(v-if="client.default_reminder_template != null && reminderTemplateOptions.length") {{ reminderTemplateOptions.find(rt => rt.content == client.default_reminder_template).label }}
                    b-form-group(v-if="$store.state.groups_enabled" :label="`Group`" label-for="group")
                        b-form-select(id="group" v-model="client.group_id" :options="groupOptions" required)
                    b-form-group(label="Visibility" label-for="visibility" v-if="!$store.state.no_public_clients")
                        b-form-select(id="visibility" v-model="client.visibility" required)
                            //option(:value="0") Inactive
                            option(:value="1") Private
                            option(:value="2" v-if="client.status !== 0") Public
                    b-form-group(label="Status" label-for="status")
                        b-form-select(id="status" v-model="client.status" required)
                            option(:value="1") {{$.active}}
                            option(:value="0") {{$.inactive}}
                    b-form-group(label="Date Closed" label-for="dateclosed" v-if="client.status === 0")
                        b-form-input(id="dateclosed" v-model="client.date_closed" type="date" required)
                    template(v-if="$store.state.risk_monitoring")
                        hr
                        b-form-group(:label="!client.dob_unsure ? `Date Of Birth`:`Approximate Age`" label-for="dob" )
                            b-form-input.mb-2(id="dob" v-model="dob_composite" :type="!client.dob_unsure ? `date`:`number`")
                            b-checkbox(switch v-model="client.dob_unsure") Unsure of exact date of birth
                        b-form-group(label="Flag as Vulnerable" label-for="isVulnerable")
                            b-form-radio-group(id="isVulnerable" v-model="client.isVulnerable" :options="vul_options" stacked)
                        b-form-group(label="Remarks" label-for="remarks")
                            b-textarea#remarks(v-model="client.remarks")
                    b-button(type="submit" :disabled="isSubmitting") Save
                hr
                div.d-flex.align-items-center.my-4
                    b-button.mr-2(v-b-modal.delete-modal variant='outline-danger' :disabled="isLoading || isSubmitting") 
                        b-icon.mr-2(icon="trash")
                        | Delete Client
                    span Caution! This will permanently delete this client. Alternatively, you can set the client status to {{$.inactive}} and keep the client's history.
                

                b-modal(id="delete-modal" title="Delete Client?" @ok="deleteClient" cancel-variant="outline-secondary" ok-variant="danger" ok-title="Delete")
                    p This will permanently delete this client!
                    p Alternatively, you can set the client status to {{$.inactive}} and keep the client's history.

                b-modal(id="transfer-modal" title="Transfer Client" v-if="$store.state.allow_reassign_client" @ok="transferClient" cancel-variant="outline-secondary" ok-variant="secondary" ok-title="Transfer")
                    b-form-group(label="Current owner:")
                        b-form-input(:value="ownerName" readonly)
                    b-form-group(label="Transfer to:" label-for="owner")
                        b-overlay(:show="loadingUsers" no-center rounded opacity="0" spinner-small)
                            template(#overlay)
                                div.d-flex.h-100.justify-content-end.p-2
                                    b-spinner(small)
                            v-select.my-1(v-model="clientOwner" @search="searchUser" :filterable="false" :options="ownerOptions")
                                template(#search="{attributes, events}")
                                    input(class="vs__search" v-bind="attributes" v-on="events" :placeholder="clientOwner ? '' : 'Start typing to search for user...'")

            
    
</template>

<script>
import dayjs from 'dayjs';
export default {
    name: 'EditClient',
    data() {
        let client = {
                name: "",
                nickname: "",
                contact_number: "",
                case_id: "",
                default_schedule: "",
                default_reminder_template: null,
                visibility: 1,
                group_id: "",
                client_groups: [],
            };
        if(this.$store.state.risk_monitoring){
            Object.assign(client,{
                dob:"",
                dob_unsure:false,
                isVulnerable:0,
                remarks:""
            })
        }
        return {
            isLoading: true,
            loadingUsers: false,
            loadingClientGroups: false,
            isSubmitting: false,
            userTimeout: null,
            clientGroupTimeout: null, //debounce timeout
            clientGroupOptions: [],
            reminderScheduleOptions: [],
            reminderTemplateOptions: [],
            groupOptions: [{ value: "", text: "" }],
            ownerOptions: [],
            clientOwner: null,
            ownerName: "",
            clientName: '',
            client,
            isError: false,
            errorMessage: ""
        };
    },
    computed: {
        dob_composite:{
            get(){
                return this.client.dob_unsure ? dayjs().diff(this.client.dob,"year") :this.client.dob;
            },
            set(v){
                if(this.client.dob_unsure){
                    if(Number(v) < 18){
                        this.client.dob= dayjs().endOf("Year").subtract(v,"year").format("YYYY-MM-DD")
                    }else{
                        this.client.dob= dayjs().startOf("Year").subtract(v,"year").format("YYYY-MM-DD")
                    }
                    return;
                }
                this.client.dob = v
            }
        },
        vul_options(){
            return[
                {value:0,text:`Default (by age cut off)`},
                {value:1,text:`Mark as vulnerable`},
                {value:2,text:`Mark as not vulnerable`}
            ]
        },
        localisation(){
            return this.$store.state.localisation;
        },
        $(){
            let output ={
                "clientGroup": "Client Group",
                "active": "Active",
                "inactive": "Inactive",
                "clientName": "Name"
            };
                    
            Object.assign(output,this.localisation);
            return output;
        },
        reminder_schedules(){
            return this.$store.state.reminder_schedules;
        },
        reminder_templates(){
            return this.$store.state.reminder_templates;
        },
        user_groups(){
            return this.$store.state.user_groups||[];
        },
        users(){
            return this.$store.state.users.value;
        },
    },
    async mounted() {
        await this.getClient(); // preload required data for next query 
        let promises = [
            //this.fetchClientGroups(),
            this.fetchReminderSchedules(),
            this.fetchReminderTemplates(),
            this.getUserGroups(),
        ];

        await Promise.all(promises);

        for(let group of this.user_groups) {
            let mainGroup = this.$store.state.group_main_types;
            if(mainGroup && !mainGroup.includes(group.group_type)) {
                continue;
            }
            this.groupOptions.push({
                value: group.id,
                text: group.group_name
            });
        }

        this.isLoading = false;
    },
    methods: {
        dayjs,
        async getClient() {
            await this.$store.dispatch('getClient',this.$route.params.client_id)
            .then(result=>{

                if(this.$store.state.risk_monitoring){
                    this.client.dob = result.dob?dayjs(result.dob).format("YYYY-MM-DD"):"";
                    this.client.dob_unsure= Boolean(result.dob_unsure)||false;
                    this.client.isVulnerable= result.isVulnerable||0;
                    this.client.remarks=result.remarks||"";
                }
                
                this.clientName = result.name;
                this.client.name = result.name;
                this.client.nickname = this.$store.state.enable_client_nickname ? result.nickname : null;
                this.client.contact_number = result.contact_number ? result.contact_number.toString() : ""; //need to return string or vue-tel will not work properly
                this.client.case_id = result.case_id;
                this.client.default_schedule = result.default_schedule;
                this.client.default_reminder_template = result.default_reminder_template;
                this.client.visibility = result.visibility === 0 ? 1 : result.visibility;
                this.client.status = result.visibility === 0 ? 0 : 1;
                this.client.group_id = result.group_id;
                this.client.date_closed = result.date_closed ? dayjs(result.date_closed).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD');
                this.client.client_groups = result.client_groups.map(clientGroup => {
                        return {
                            label: clientGroup.name,
                            content: clientGroup.id
                        }
                    });
                this.ownerName = result.owner;

                //add the clients default reminder to option if any
                if(result.reminder_template) {
                    this.reminderTemplateOptions.push({
                        label: result.reminder_template.content.reminder,
                        name: result.reminder_template.name ? result.reminder_template.name : result.reminder_template.content.reminder,
                        content: result.reminder_template.id
                    });
                }
                if(result.reminder_schedule) {
                    this.reminderScheduleOptions.push({
                        //Remove the Phone from Edit Client
                        label: 'SMS: '+ result.reminder_schedule.trigger_schedule.sms.join(", ") +' days ' +  (!this.$store.state.disable_phone_reminder ? '| Phone: '+ result.reminder_schedule.trigger_schedule.phone.join(", ") +' days' : ''),
                        content: result.reminder_schedule.id
                    });
                }
            })
            .catch((err)=>{
                if(err.response.status == 404) {

                console.log(err);

                // todo: display error 404

                }
            });
        },
        async searchClientGroup(search) {
            // debounce
            if (this.clientGroupTimeout)
                clearTimeout(this.clientGroupTimeout); 

            this.clientGroupTimeout = setTimeout(async() => {
                // your action
                this.clientGroupOptions = [];
                if(!search) {
                    return;
                }
                
                await this.fetchClientGroups(search);
            }, 500); // delay
        },
        async fetchClientGroups(filter=null) {
            this.loadingClientGroups = true;

            let params = {}
            if(filter) {
                params = {
                    top: 10,
                    filterby: "name",
                    filter
                }
            }
            await this.$store.dispatch('fetchAllClientGroups', params).then((result)=>{
                // Prepare option for client group select
                for (let clientGroup of result.value){
                    let option = {
                        label: clientGroup.name,
                        content: clientGroup.id,
                    };

                    this.clientGroupOptions.push(option);
                }
            })
            .catch(()=>{
                // do something
            });

            this.loadingClientGroups = false;
        },
        async fetchReminderSchedules() {
            
            await this.$store.dispatch('fetchAllReminderSchedule').then(result=>{
                for (var i=0; i<result.length; i++){
                    if(result[i].id === this.client.default_schedule) { continue; }

                    let option = {
                        //Remove the Phone from Edit Client
                        label: 'SMS: '+ result[i].trigger_schedule.sms.join(", ") +' days ' +  (!this.$store.state.disable_phone_reminder ? '| Phone: '+ result[i].trigger_schedule.phone.join(", ") +' days' : ''),
                        content: result[i].id
                    };

                    this.reminderScheduleOptions.push(option);
                }    
            })
            .catch(()=>{
                // do something
            });
        },
        async fetchReminderTemplates() {
            await this.$store.dispatch('fetchAllReminderTemplate').then((result)=>{
                for (var i=0; i<result.length; i++){
                    if(result[i].id === this.client.default_reminder_template) { continue; }

                    let option = {
                        label: result[i].content.reminder,
                        name: result[i].name ? result[i].name : result[i].content.reminder,
                        content: result[i].id
                    };

                    this.reminderTemplateOptions.push(option);
                }
            })
            .catch(()=>{
                // do something
            });
        },
        async editClient(redirect=true) {
            this.isSubmitting = true;
            this.isError = false;
            this.errorMessage = "";
            // change visibility based on status field
            let payload = { ...this.client }; // copy obj
            payload.client_groups = payload.client_groups ? payload.client_groups.map(cg=>cg.content) : []; // map client group ids
            payload.visibility = payload.status === 0 ? 0 : payload.visibility;
            payload.date_closed = payload.status === 0 ? payload.date_closed : null; // remove date_closed if client still active
            delete payload.status;

            if(!this.$store.state.enable_client_nickname) { delete payload.nickname }

            // regex check phone no
            let regex = /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/;
            let contact_number = payload.contact_number.replace(/\s/g, '');
            //Ensure that the invalid contact number is executed when it does not match regex & is not empty
            if(!contact_number.match(regex) && !(this.$store.state.optional_client_contact_number && contact_number==='')) {
                this.isError = true;
                this.errorMessage = "Invalid contact number, expected format +XXXXXXXXXX";
                //Vue updates the dom asynchronously, To reflect changes immediately use vm.$nextTick(callback)
                this.$nextTick(() => {this.$refs.errorMsg.$el.scrollIntoView();});
                this.isSubmitting = false;
                return false;
            }

            await this.$store.dispatch('patchClient',{payload:payload, client_id:this.$route.params.client_id}).then(result=>{
                if(redirect) { window.location.href = `/clients/${result.id}`; }
            }).catch((err)=>{
                this.isError = true;
                this.errorMessage = err;
                this.errorMessage += ". Contact number may already exist in your client list.";
                this.$nextTick(() => {this.$refs.errorMsg.$el.scrollIntoView();});
            });
            this.isSubmitting = false;
        },
        async transferClient() {
            this.isSubmitting = true;
            // Save any changes in client before transfer
            await this.editClient(false);

            if(!this.clientOwner) {
                return;
            }

            let payload = {
                user_id: this.clientOwner.value
            }

            await this.$store.dispatch('patchClient',{payload:payload, client_id:this.$route.params.client_id}).then(()=>{
                window.location.href = `/clients`;
            }).catch((err)=>{
                this.isError = true;
                this.errorMessage = err;
            });
            this.isSubmitting = false;
        },
        async deleteClient() {
            this.isSubmitting = true;
            await this.$store.dispatch('deleteClient', this.$route.params.client_id).then(()=>{
                window.location.href = `/clients`;
            }).catch((err)=>{
                this.isError = true;
                this.errorMessage = err;
                this.isSubmitting = false;
            });
        },
        async getUserGroups() {
            await this.$store.dispatch('getUserGroups').then(()=>{
            })
            .catch(()=>{
                // do something
            });
        },
        async searchUser(search) {
            // debounce
            if (this.userTimeout)
                clearTimeout(this.userTimeout); 

            this.userTimeout = setTimeout(async() => {
                // your action
                this.ownerOptions = [];
                if(!search) {
                    return;
                }
                
                await this.getAllUser(search);
            }, 500); // delay
        },
        async getAllUser(filter=null) {
            this.loadingUsers = true;

            let params = {}
            if(filter) {
                params = {
                    top: 10,
                    filterby: "graph_display_name",
                    filter
                }
            }

            await this.$store.dispatch('getAllUser',params).then((result)=>{
                for (let cw of result.value){
                    let option = {
                        label: `${cw.graph_display_name} (${cw.graph_user_principal_name})`,
                        value: cw.id
                    };

                    this.ownerOptions.push(option);
                }
            })
            .catch(()=>{
                // do something
            });

            this.loadingUsers = false;
        },
    }
}
</script>

<style>

</style>