<template lang="pug">
  div
    div(:class="{'d-none':loading}")
      div(v-if="$store.state.placefinder")
        div.d-flex.justify-content-between
          b-form(inline)
            label.mr-1 Display Mode:
            b-overlay(:show="refreshing" spinner-type="none")
              b-radio-group( buttons :options="[`Appointments Only`,`Resource Usage`]" button-variant="outline-secondary" size="sm" v-model="displayMode")
          b-form.ml-3(inline)
            label.mr-1(for="dateselect") Date:
            b-form-datepicker(id="dateselect" v-model="selectedDate" size="sm")
        hr

      div.bg-white.border.rounded.py-1.px-2.mb-2
        b-form(inline)
          div.d-flex(v-if="displayMode==`Appointments Only`")
            b-form(inline)
                label.mr-1(for="group") Split by:
                b-form-select(id="group" v-model="selectedSplit" size="sm" :options="availableSplit")
            b-form.ml-3(inline)
                label.mr-1(for="filterby") Filter by:
                b-form-select(id="filterby" v-model="selectedFilter" size="sm" :options="availableFilters" @change="fetchData()")
                b-form-select(v-model="selectedGroupFilter" v-if="selectedFilter !== null && selectedFilter.type == 'group'" size="sm")
                  b-form-select-option(:value="null") All Appointment
                  b-form-select-option(value="own") My Appointment
                  b-form-select-option(value="other") Others Appointment

          template(v-if="viewMode !='month'")
            b-form.ml-auto(inline)
              b-form-checkbox(id="hideWeekends" v-model="hideWeekends" size="sm") Hide Weekends
            b-form.ml-3(inline)
              b-form-checkbox(id="showNonWorkhours" v-model="showNonWorkhours" size="sm") Show Non Workhours
        b-form-group.m-0.mb-1(v-if="displayMode==`Resource Usage`" label="Track Resource Usage")
          v-select.mb-1( :options="locations" label="name" placeholder="Filter by Location" v-model="selectedLocation")
          template(v-if="!!selectedLocation")
            v-select.mb-1( :options="placesFiltered" label="displayName" placeholder="Filter by Room" v-model="listofrooms" multiple)
            b-button(@click="setAllRooms" block) Select All Rooms
              small  (This may take a while to load)
            b-button.mt-1(@click="clearAllRooms" block size="sm" variant="outline-dark") Clear All Rooms

      b-overlay(:show="refreshing")
        vue-cal(
            :hide-weekends="hideWeekends" 
            events-on-month-view 
            disable-date-prototypes 
            ref="groupCal"
            :disableViews="displayMode == 'Resource Usage' ? ['years', 'year', 'month'] : ['years', 'year']" 
            @view-change="viewChange" 
            :events="events" 
            :timeFrom="timebounds.start" 
            :timeTo="timebounds.end" 
            :onEventClick="openEvent"
            :time-cell-height="80"
            today-button
            :split-days="splitInfo"
            :min-split-width="200"
            :editable-events="{ title: false, drag: false, resize: false, delete: false, create: true }"
            :on-event-create="showAppointmentCreateModal"
            @event-drag-create="$bvModal.show('new-event')"
            :snap-to-time="15"
            :drag-to-create-threshold="10"
            :selected-date="selectedDate"
          )
    p.lead.d-flex(v-if="loading") 
      b-spinner
      span.ml-3 Loading...

    <!-- modal -->
    b-modal(id="new-event" title="Create New Appointment" @close="deleteEventFunction" hide-footer no-close-on-backdrop)
      <b-alert variant="danger" :show="isError">{{errorMessage}}</b-alert>
      AppointmentForm(@appointment="createAppointment($event)" :submitting="submitting" :setStart="selectedEvent.start ? selectedEvent.start : null" :setEnd="selectedEvent.end ? selectedEvent.end : null" :setRoomId="selectedEvent.split ? selectedEvent.split : null")

      //b-button.mr-1.mb-1(type="submit" :disabled="isSaving") {{ isSaving ? 'Saving...' : 'Save' }}
      b-button.mt-1(@click="deleteEventFunction();$bvModal.hide('new-event');" variant="outline-danger") Cancel
</template>
<script>
import dayjs from 'dayjs';
import axios from 'axios';
import AppointmentForm from '@/components/appointments/form.vue';

export default {
  data(){
    return{
      displayMode:"Appointments Only",
      loading:true,
      refreshing:false,
      activeTab: 0,
      selectedFilter:"own",
      selectedGroupFilter:null,
      selectedSplit:'None',
      hideWeekends: false,
      showNonWorkhours: false,
      viewMode: "week",
      otherCal:{},
      listofrooms:[],
      selectedLocation:null,
      selectedDate: dayjs().format('YYYY-MM-DD'),
      fetchedappointments:[],
      roomschedules:[],
      selectedEvent: {},
      deleteEventFunction: false, //function to remove temporary event on calender on cancel
      submitting: false, //loading appointment create on submit
      isError: false,
      errorMessage: "",
    }
  },
  components: {
    AppointmentForm
  },
  computed: {
    hidecal(){
      if(this.displayMode == "Resource Usage" && !this.listofrooms.length){
        return true;
      }
      return false;
    },
    availableSplit(){
      return [
        'None',
        {
          text:"Locations",
          value: "Location"
        },
        {
          text:"M365 Groups",
          value: "Group"
        }

      ]
    },
    timebounds(){
      return this.showNonWorkhours?{start:0, end:24*60}:{start:8*60, end:22*60}
    },
    places(){
      return this.$store.state.places||[];
    },
    user(){
      return this.$store.state.user;
    },
    locations(){
      let output = [];
      let unique = new Set();
      for(let place of this.places){
        if(place?.roomListId && !unique.has(place.roomListId)){
          unique.add(place.roomListId);
          output.push(place.roomListInfo);
        }
      }

      // sort a-z
      output.sort(function(a, b) {
          var textA = a?.name?.toUpperCase?.();
          var textB = b?.name?.toUpperCase?.();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      return output;
    },
    placesFiltered(){
      let location = this.selectedLocation.id;
      let output = this.places.filter(place=>place.roomListId == location)||[];
      // sort a-z
      output.sort(function(a, b) {
          var textA = a.displayName.toUpperCase();
          var textB = b.displayName.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      return output;
    },
    user_groups(){
      return this.$store.state.user_groups||[];
    },
    availableGroups(){
      let output =  [];
      let unique = new Set();

      if(this.user && this.user.user_groups_w_details && this.user.user_groups_w_details.length > 0) {
        for(let group of this.user.user_groups_w_details) {
          if(unique.has(group.id)){
            return;
          }
          unique.add(group.id);

          output.push({
            value: {type:"group", id:group.id},
            text: group.group_name,
          })
        }
      }
      else {
        this.appointments.forEach(appt=>{
          if(!appt.group_id){
            return;
          }
          if(unique.has(appt.group_id)){
            return;
          }
          unique.add(appt.group_id);
          output.push({
            value: {type:"group", id:appt.group_id},
            text: this.findGroupName(appt.group_id)
          })
        });
      }
      
      return output;    
    },
    availableLocationsByRoomList(){
      let output =  [];
      let tempOutput ={
        none:{
          label: "Filter By Locations:",
          options:[]
        }
      };
      let unique = new Set();
      this.appointments.forEach(appt=>{
        if(!appt.room_id){
          return;
        }
        if(unique.has(appt.room_id)){
          return;
        }
        unique.add(appt.room_id);

        let roomDetails ={
          value: {type:"room", id:appt.room_id},
          text: this.findRoomName(appt.room_id)
        };

        let room = this.findRoomInfo(appt.room_id);

        if(!room){
          tempOutput.none.options.push(roomDetails)
        }
        if(!tempOutput[room.roomListId]){
          tempOutput[room.roomListId] = {
            label: "— "+room.roomListInfo?.name,
            options: []
          }
        }
        tempOutput[room.roomListId].options.push(roomDetails)
        
      });
      for(let id in tempOutput){
        output.push(tempOutput[id]);
      }
      return output;
    },
    availableLocations(){
      let output =  [];
      let unique = new Set();
      this.appointments.forEach(appt=>{
        if(!appt.room_id){
          return;
        }
        if(unique.has(appt.room_id)){
          return;
        }
        unique.add(appt.room_id);

        output.push({
          value: {type:"room", id:appt.room_id},
          text: this.findRoomName(appt.room_id)
        })
      });
      return output;
    },
    availableFilters(){
      let output =  [
        { value: null, text: 'All Appointment' },
        { value: 'own', text: 'My Appointment' },
      ];

      // check if supervisor to include supervisee option, and add group filter
      let userOwnedGroups = this.$store.state.group_view_all_appointment ? this.user.user_groups_w_details : this.user.user_owned_groups_w_details;
      if(userOwnedGroups && userOwnedGroups.length) {
          let allowedGroups = userOwnedGroups;
          let mainGroup = this.$store.state.group_main_types;
          let supervisorGroup = this.$store.state.group_supervisor_types;
          let allowedGroupTypes = [];

          if(supervisorGroup && supervisorGroup.length > 0) {
            let groups = userOwnedGroups.filter(group=>supervisorGroup.includes(group.group_type) && group.owner);
            if(groups.length) {
              output.push({ value: 'suprevisee', text: 'Supervisee' })
            }
          }

          if(mainGroup && mainGroup.length > 0) { allowedGroupTypes.push(...mainGroup); }

          if(allowedGroupTypes.length) {
              allowedGroups = userOwnedGroups.filter(group=>allowedGroupTypes.includes(group.group_type));
          }

          if(allowedGroups.length) {
              output.push({
                  label: "Groups / Teams",
                  options: allowedGroups.map(grp=>{ return {value:{type:"group", id:grp.id},text:grp.group_name} })
              });
          }
      }

      //if(this.availableGroups && this.availableGroups.length > 0) {
      //  output.push({
      //    label: "Filter by Groups / Teams",
      //    options: this.availableGroups 
      //  });
      //}

      if(this.availableLocationsByRoomList && this.availableLocationsByRoomList.length > 1) {
        output.push(...this.availableLocationsByRoomList);
      }
      
      return output;

    },
    appointments(){
      //return this.$store.state.appointments.value ||[];
      return this.fetchedappointments || [];
    },
    startTime(){
      return dayjs(this.$refs.groupCal?.view?.startDate).tz("Asia/Singapore",true).utc();
    },
    endTime(){
      return dayjs(this.$refs.groupCal?.view?.endDate).tz("Asia/Singapore",true).utc();
    },
    events(){
      let output = [];
      if(this.displayMode == "Appointments Only"){
        this.appointments.forEach(appt=>{
          //console.log((this.selectedFilter?.type =='group' && appt.group_id === this.selectedFilter?.id && this.selectedGroupFilter=='own'), appt.id, appt.user_id);
          //console.log('user',appt.user_id !== this.user.user_id);

          if(appt.cancelled){
            return;
          }
          if(this.selectedFilter == 'own' && appt.user_id !== this.user.user_id){
            return;
          }
          if((this.selectedFilter?.type =='group' && this.selectedGroupFilter=='own' && (appt.group_id === this.selectedFilter?.id && appt.user_id !== this.user.user_id))) {
            return;
          }
          if((this.selectedFilter?.type =='group' && this.selectedGroupFilter=='other' && (appt.group_id === this.selectedFilter?.id && appt.user_id === this.user.user_id))) {
            return;
          }
          if(this.selectedFilter?.type =='group' && appt.group_id !== this.selectedFilter?.id){
            return;
          }
          if(this.selectedFilter?.type =='room' && appt.room_id !=this.selectedFilter?.id){
            return;
          }
          let split = "none";
          let colorclass = 'dark-sp'
          if(this.selectedSplit=='Location' && appt.room_id){
            split = appt.room_id;
            colorclass = this.splitInfo.find(spl=>{
              return spl.id ==appt.room_id;
            }).cellClass
          }

          if(this.selectedSplit=='Group' && appt.group_id){
            split = appt.group_id;
            colorclass = this.splitInfo.find(spl=>{
              return spl.id ==appt.group_id;
            }).cellClass          
          }

          //let duration = dayjs(appt.end).diff(dayjs(appt.start), 'minute');

          output.push({
            id:appt.id,
            title: appt.subject,
            start: dayjs(appt.start).tz("Asia/Singapore").format("YYYY-MM-DD HH:mm"),
            end: dayjs(appt.end).tz("Asia/Singapore").format("YYYY-MM-DD HH:mm"),
            content:`<small>${appt.organiser.graph_display_name}</small>`,
            class: `bg-${colorclass} text-light font-weight-bold event-click border border-${colorclass} rounded`, //${duration<60 || (appt.subject.length > 20 && duration<105) ? 'event-on-hover-expand' : 'event-on-hover'}`,
            split
          })
        })
      }else{
        this.roomschedules.forEach((rm, split)=>{
          
          let colorclass = this.splitInfo[split]?.cellClass;
          
          rm.value.forEach(event=>{
            //let duration = dayjs(event.end.dateTime).diff(dayjs(event.start.dateTime), 'minute')

            output.push({
              graph_id: event.id,
              title:event.subject,
              start: dayjs(event.start.dateTime).tz("Asia/Singapore").format("YYYY-MM-DD HH:mm"),
              end: dayjs(event.end.dateTime).tz("Asia/Singapore").format("YYYY-MM-DD HH:mm"),
              content:`<small>${event?.organizer?.emailAddress?.name}</small>`,
              class: `bg-${colorclass} text-light font-weight-bold event-click border border-${colorclass} rounded`, //${duration < 60 || (event.subject > 20 && duration<105) ? 'event-on-hover-expand' : 'event-on-hover'}`,
              split: rm.id
            })
          })
        })
      }
      return output;
    },
    splitInfo(){
      let output = []
      let index = 0;
      if(this.displayMode == "Resource Usage"){
        output.push(...this.listofrooms.map((rm,id)=>{
          let dex = (1+id)%5 +1;
          return {
            id: rm.id,
            label:rm.displayName,
            cellClass:`cal${dex}`,
            class: `bg-cal-split${dex}`
          }
        }));
        return output;

      }
      if(this.selectedSplit=='Location'){
        //loop every 5 indexes
        output.push({
          id:"none",
          label: "No Locations"
        });

        for(let location of this.availableLocations){
          let dex = index++%5 +1;
          output.push({
            id: location.value.id,
            class: `bg-cal-split${dex}`,
            cellClass:`cal${dex}`,
            label: location.text
          })
        }
      
        return output;
      }
      if(this.selectedSplit=='Group'){
        output.push({
          id:"none",
          label: "No Group"
        });

        for(let group of this.availableGroups){
          let dex = index++%5 +1;

          output.push({
            id: group.value.id,
            class: `bg-cal-split${dex}`,
            cellClass:`cal${dex}`,
            label: group.text
          })
        }
        return output;
      }

      return [];
    }

  },
  async mounted() {
    let promises = [
      this.$store.dispatch('fetchAllPlaces'),
      this.$store.dispatch('getUserGroups')
    ]
    await Promise.allSettled(promises);

    await this.fetchData();
    this.hideWeekends = true;
    this.loading = false;
  },
  methods: {
    dayjs,
    clearAllRooms(){
      this.listofrooms = [];
    },
    setAllRooms(){
      if(this.selectedLocation?.id){
        this.listofrooms = this.places.filter(place=>place.roomListId == this.selectedLocation.id);
        this.listofrooms.sort(function(a, b) {
          var textA = a.displayName;
          var textB = b.displayName;
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
      }
    },
    openEvent(ev){
      if(ev.id){
        this.$router.push('/appointments/'+ev.id);
      }
      
    },
    findRoomInfo(id){
      return this.places.find(place=>place.id ==id)
    },
    findRoomName(id){
      let place = this.findRoomInfo(id);
      return place?.displayName||[place?.roomListInfo?.name , place?.displayName].join(' - ');
    },
    findGroupName(id){
      return this.user_groups.find(grp=>grp.id ==id).group_name;
    },
    async fetchData(){
      return  this.displayMode == "Appointments Only" ? this.fetchClients() : this.fetchSchedule();
    },
    async fetchSchedule(){
      if(!this.listofrooms.length){
        return;
      }
      let offset = 0;
      if(this.viewMode == 'month'){
        offset = 11;
      }
      this.refreshing = true;

      let start = dayjs(this.$refs.groupCal?.view?.startDate).tz("Asia/Singapore",true).utc();
      let end = dayjs(this.$refs.groupCal?.view?.endDate).tz("Asia/Singapore",true).utc();

      let startdatetime = start.subtract(offset,'d').tz("UTC").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      let enddatetime = end.add(offset,'d').tz("UTC").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      let qs = new URLSearchParams({startdatetime,enddatetime})

      let rs = [];
      let getroom = (room, tries)=>{
        if(!tries){
          tries = 0;
        }
        return  axios.get(`/api/ms_graph/places/${room}?${qs.toString()}`).then(rsp=>rsp.data.result).catch(()=>{
            if(tries <5){
              return getroom(room, tries+1)
            }
            return {
              value:[]
            }
            
        })
      }
      for(let room of this.listofrooms){
        
        rs.push(getroom(room.emailAddress));
      }
      this.roomschedules = await Promise.all(rs)

      this.refreshing = false;
      return 
    },
    async fetchClients() {
      let offset = 0;
      if(this.viewMode == 'month'){
        offset = 11;
      }

      this.refreshing = true;

      let start = dayjs(this.$refs.groupCal?.view?.startDate).tz("Asia/Singapore",true).utc();
      let end = dayjs(this.$refs.groupCal?.view?.endDate).tz("Asia/Singapore",true).utc();

      let startdatetime = start.subtract(offset,'d').toISOString();
      let enddatetime = end.add(offset,'d').toISOString();

      let params = {
        start: startdatetime,
        end: enddatetime
      }

      // fetchGroupAppointments - fetch all appointment including groups members appt
      // fetchMyAppointments - fetch all logged in user appointment, exclude groups members appt
      let action = (this.selectedFilter==="own") ? "fetchMyAppointments" : "fetchGroupAppointments";

      // list by supervisor group
      if(this.selectedFilter === "suprevisee") {
        params = { ...params, grouptype: 'supervisor' }
      }
      // filter by group
      if(this.selectedFilter?.type == 'group') {
          params = { ...params, group_id: this.selectedFilter.id }
      }
      
      let rs = await this.$store.dispatch(action,params).then((result)=>{
        this.fetchedappointments = result.value;
      })
      .catch(()=>{
        // do something
      });
      this.refreshing = false;
      return rs;
    },
    viewChange(e){
      this.viewMode = e.view
      if(e.view == "month"){
        this.hideWeekends = false;
        this.showNonWorkhours = false;
      }
      this.fetchData()
      return true;
    },
    // Called when drag-create threshold is reached (when the event appears on screen),
    // but before releasing the drag; so, it should not open the dialog box yet.
    showAppointmentCreateModal(event, deleteEventFunction) {
      this.selectedEvent = event;
      this.deleteEventFunction = deleteEventFunction;
      //console.log(event, deleteEventFunction);
      return event
    },
    async createAppointment(appointment) {
      this.submitting = true;
      await this.$store.dispatch('createAppointment',{payload: appointment}).then((result)=>{
        window.location.href = `/appointments/${result.id}`;
      }).catch((err)=>{
        this.isError = true;
        this.errorMessage = err;
      })
      this.submitting = false;
    },
  },
  watch:{
    selectedLocation(){
      this.clearAllRooms();
    },
    listofrooms:{
      deep: true,
      handler(){
        this.fetchData();
      }

    },
    selectedFilter(v){
      if(!v){
        return;
      }
      if(v.type == 'room' && this.selectedSplit== "Location"){
        this.selectedSplit= "Group"
      }
      if(v.type == 'group' && this.selectedSplit== "Group"){
        this.selectedSplit= "Location"
      }

    }
  }
}
</script>
<style lang="scss">

//.event-on-hover:hover {
//  box-shadow: 0 .5em 0 rgba(0,0,0,0.5) !important;
//  border: .1em solid rgba(0,0,0,0.125) !important;
//}
//.event-on-hover-expand:hover {
//  height:auto !important;
//  box-shadow: 0 .5em 0 rgba(0,0,0,0.5) !important;
//  border: .1em solid rgba(0,0,0,0.125) !important;
//}
.vuecal {
  border-radius: .25em;
  background-color: white;
}
.vuecal__menu {
  background-color: #383874 !important;
  color: #fff !important;
  border-radius: .25em .25em 0 0 !important;
}
.vuecal__title-bar {
  background-color: rgb(224, 229, 238) !important;
}
.vuecal__cell--selected {
  background-color: rgba(224, 229, 238, .25) !important;
}
.vuecal__event{
  background-color: rgba(41, 95, 194, 0.25) !important;
}
.vuecal__event:hover{
  display:table !important;
  box-shadow: 0 .5em 0 rgba(0,0,0,0.5) !important;
  border: .1em solid rgba(0,0,0,0.125) !important;
}
.vs__dropdown-toggle {
  background-color: white !important;
}
//&:not(.vuecal--day-view) .vuecal__cell--selected {background-color: transparent;}
//&:not(.vuecal--day-view).full-cal .vuecal__cell--selected:before {border: 1px solid rgba(#383874, 0.8);}
</style>