<template lang="pug">
  .about
    h2 Reminders
      
    b-tabs()
      b-tab(title="Schedule" :active="activeTab == 'schedule'")
        b-card.shadow.border-top-0.rounded-0
            ReminderSchedule
      b-tab(title="Template" :active="activeTab == 'template'")
        b-card.shadow.border-top-0.rounded-0
          ReminderTemplate
</template>
<script>
import ReminderSchedule from '@/components/reminders/reminder_schedule.vue';
import ReminderTemplate from '@/components/reminders/reminder_template.vue';

export default {
  name: 'Reminders',
  certaintyMenu(link){
    return {iconType: 'fas', icon: 'bell', link, description: 'Reminders', order:3}
  },
  components: {
    ReminderSchedule,
    ReminderTemplate
  },
  data(){
    return{
      activeTab: 'schedule'
    }
  },
  computed: {
    
  },
  mounted() {
    if(this.$route.query.tab == 'template') {
      this.activeTab = 'template';
    }
    else {
      this.activeTab = 'schedule';
    }
  },
  methods: {
    
  }
}
</script>