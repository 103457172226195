<template lang="pug">
#login
    .d-flex.justify-content-center.align-items-center.full-height
      b-card.login-card.shadow.border-0(:class="!$root.inMobile ? 'px-5 pb-2 py-2' : 'px-3 py-2'")
        p.text-center.mb-0(v-if="isTestEnv") TEST ENVIRONMENT
        .d-flex.justify-content-center
          LogoWithoutText.logo-text
        .d-flex.flex-column.align-items-center.my-3
          h2.certainty-text.ct-top {{appName}}
          .certainty-text.ct-bottom {{app_type}}
          .small.text-center.mt-1.ct-tq
            | Powered by 
            img(src='/TQlogo.svg' alt='tqlogo' style='height:15px;')
            span.thunderlogo Thunder
            span.quotelogo Quote
        
        .mt-4
          template(v-if='!loading && login_enabled.password')
            b-form-group.mb-0.login-group-1(label='Username or Email:' label-for='email')
              b-form-input#password(v-model='login_data.username' placeholder='Enter your email or username')
            b-form-group.mt-3.login-group-2(label='Password:' label-for='input-formatter')
              b-form-input#input-formatter.login-group-3(type='password' v-model='login_data.password' placeholder='Enter your password')
          .login-group-4
            b-button.d-flex.align-items-center.justify-content-center(v-if='login_enabled.password' squared='' :disabled='loading' variant='tq' block='')
              Hourglass.loading-hourglass.mr-1(v-if='loading')
                b-icon.mr-2(icon='box-arrow-in-right')
                  div(:class="loading ? '' : ''") {{!loading ? &apos;Sign in with password&apos; : &apos;Signing in&apos;}}
            template(v-if='!loading')
              b-button.d-flex.align-items-center.justify-content-center.text-dark(v-if='login_enabled.singpass' squared='' :disabled='loading' variant='outline-dark-sp' block='')
                .d-flex.align-items-center(v-if='!loading')
                  b-img.mr-2(style='max-height:20px;' src='/qr-code.svg' alt='qrcode')
                    span.mr-1(style='white-space:nowrap') Sign in with
                    b-img.mt-1(style='max-height:20px;' src='/singpasslogo.png' alt='SingPass')
              b-button.d-flex.align-items-center.justify-content-center.py-0(v-if='login_enabled.microsoft' squared='' :disabled='loading' href='/api/login/?type=microsoft' variant='dark-ms' block='')
                template(v-if='!loading')
                  svg(xmlns='http://www.w3.org/2000/svg' width='215' height='41' viewbox='0 0 215 41')
                    title MS-SymbolLockup
                    rect(width='215' height='41' fill='rgba(0,0,0,0)')
                    path(d='M45.812,25.082V23.288a2.77,2.77,0,0,0,.573.4,4.484,4.484,0,0,0,.706.3,5.486,5.486,0,0,0,.745.187,3.954,3.954,0,0,0,.687.065,2.928,2.928,0,0,0,1.634-.365,1.2,1.2,0,0,0,.537-1.062,1.167,1.167,0,0,0-.178-.649,1.939,1.939,0,0,0-.5-.5,5.412,5.412,0,0,0-.757-.435q-.435-.209-.932-.436-.533-.285-.994-.578a4.285,4.285,0,0,1-.8-.648,2.724,2.724,0,0,1-.533-.8,2.6,2.6,0,0,1-.194-1.047,2.416,2.416,0,0,1,.333-1.285,2.794,2.794,0,0,1,.877-.9,4.019,4.019,0,0,1,1.239-.528,5.906,5.906,0,0,1,1.418-.172,5.692,5.692,0,0,1,2.4.374v1.721a3.817,3.817,0,0,0-2.295-.645,4.093,4.093,0,0,0-.771.074,2.335,2.335,0,0,0-.687.241,1.5,1.5,0,0,0-.494.433,1.06,1.06,0,0,0-.189.637,1.221,1.221,0,0,0,.145.608,1.573,1.573,0,0,0,.428.468,4.321,4.321,0,0,0,.688.414c.27.134.584.28.939.436q.548.285,1.034.6a4.881,4.881,0,0,1,.856.7,3.075,3.075,0,0,1,.585.846,2.493,2.493,0,0,1,.215,1.058,2.625,2.625,0,0,1-.322,1.348,2.584,2.584,0,0,1-.866.892,3.786,3.786,0,0,1-1.254.5,6.959,6.959,0,0,1-1.5.155c-.176,0-.392-.014-.647-.04s-.518-.067-.786-.117a7.75,7.75,0,0,1-.76-.187A2.373,2.373,0,0,1,45.812,25.082Z' fill='#fff')
                    path(d='M55.109,16.426a1.021,1.021,0,0,1-.713-.272.891.891,0,0,1-.3-.688.917.917,0,0,1,.3-.7,1.009,1.009,0,0,1,.713-.278,1.041,1.041,0,0,1,.732.278.915.915,0,0,1,.3.7.9.9,0,0,1-.3.678A1.035,1.035,0,0,1,55.109,16.426ZM55.95,25.5h-1.7V18h1.7Z' fill='#fff')
                    path(d='M64.979,24.9q0,4.131-4.146,4.131a6.166,6.166,0,0,1-2.551-.491V26.986a4.712,4.712,0,0,0,2.332.7,2.341,2.341,0,0,0,2.668-2.628V24.24h-.029a2.938,2.938,0,0,1-4.733.436,4.046,4.046,0,0,1-.837-2.684,4.738,4.738,0,0,1,.9-3.04,2.988,2.988,0,0,1,2.471-1.128,2.38,2.38,0,0,1,2.2,1.216h.029V18h1.7ZM63.3,22.064v-.973a1.91,1.91,0,0,0-.523-1.352,1.71,1.71,0,0,0-1.3-.559,1.789,1.789,0,0,0-1.51.714,3.223,3.223,0,0,0-.545,2,2.78,2.78,0,0,0,.523,1.769,1.675,1.675,0,0,0,1.385.662,1.8,1.8,0,0,0,1.426-.632A2.4,2.4,0,0,0,63.3,22.064Z' fill='#fff')
                    path(d='M73.853,25.5h-1.7V21.273q0-2.1-1.483-2.1a1.616,1.616,0,0,0-1.279.582,2.167,2.167,0,0,0-.505,1.469V25.5h-1.7V18h1.7v1.245h.029a2.669,2.669,0,0,1,2.428-1.421,2.257,2.257,0,0,1,1.863.795,3.57,3.57,0,0,1,.644,2.3Z' fill='#fff')
                    path(d='M80.892,16.426a1.017,1.017,0,0,1-.713-.272.889.889,0,0,1-.3-.688.915.915,0,0,1,.3-.7,1,1,0,0,1,.713-.278,1.038,1.038,0,0,1,.731.278.915.915,0,0,1,.3.7.9.9,0,0,1-.3.678A1.033,1.033,0,0,1,80.892,16.426Zm.84,9.074h-1.7V18h1.7Z' fill='#fff')
                    path(d='M90.614,25.5h-1.7V21.273q0-2.1-1.483-2.1a1.62,1.62,0,0,0-1.28.582,2.167,2.167,0,0,0-.5,1.469V25.5h-1.7V18h1.7v1.245h.03a2.668,2.668,0,0,1,2.427-1.421,2.258,2.258,0,0,1,1.864.795,3.576,3.576,0,0,1,.643,2.3Z' fill='#fff')
                    path(d='M106.865,18l-2.208,7.5h-1.776l-1.36-5.083a3.291,3.291,0,0,1-.1-.659h-.029a3.018,3.018,0,0,1-.132.644l-1.477,5.1H98.042l-2.2-7.5H97.6l1.36,5.405a3.308,3.308,0,0,1,.087.645H99.1a3.384,3.384,0,0,1,.117-.659L100.725,18h1.593l1.345,5.428a3.832,3.832,0,0,1,.095.644h.052a3.3,3.3,0,0,1,.109-.644L105.249,18Z' fill='#fff')
                    path(d='M108.977,16.426a1.017,1.017,0,0,1-.713-.272.889.889,0,0,1-.3-.688.915.915,0,0,1,.3-.7,1,1,0,0,1,.713-.278,1.038,1.038,0,0,1,.731.278.915.915,0,0,1,.3.7.9.9,0,0,1-.3.678A1.033,1.033,0,0,1,108.977,16.426Zm.84,9.074h-1.7V18h1.7Z' fill='#fff')
                    path(d='M115.979,25.42a2.944,2.944,0,0,1-1.307.248q-2.18,0-2.179-2.094V19.333h-1.25V18h1.25V16.264l1.7-.483V18h1.79v1.333h-1.79v3.75a1.478,1.478,0,0,0,.242.952,1,1,0,0,0,.8.285,1.16,1.16,0,0,0,.745-.248Z' fill='#fff')
                    path(d='M124.094,25.5h-1.7V21.4q0-2.226-1.483-2.226a1.555,1.555,0,0,0-1.258.644,2.573,2.573,0,0,0-.511,1.649V25.5h-1.7V14.4h1.7v4.849h.029a2.679,2.679,0,0,1,2.428-1.421q2.492,0,2.492,3.055Z' fill='#fff')
                    path(d='M141.719,25.5h-1.726V18.7q0-.835.1-2.043h-.03a6.992,6.992,0,0,1-.285.988L136.652,25.5h-1.2l-3.136-7.793a7.371,7.371,0,0,1-.277-1.047h-.029q.059.63.058,2.058V25.5h-1.608V15h2.449l2.756,7a10.415,10.415,0,0,1,.409,1.2h.036c.181-.551.327-.962.439-1.23L139.357,15h2.362Z' fill='#fff')
                    path(d='M144.964,16.426a1.019,1.019,0,0,1-.713-.272.892.892,0,0,1-.3-.688.918.918,0,0,1,.3-.7,1.007,1.007,0,0,1,.713-.278,1.038,1.038,0,0,1,.731.278.911.911,0,0,1,.3.7.9.9,0,0,1-.3.678A1.033,1.033,0,0,1,144.964,16.426Zm.841,9.074h-1.7V18h1.7Z' fill='#fff')
                    path(d='M153.378,25.156a4.185,4.185,0,0,1-2.127.52,3.6,3.6,0,0,1-2.69-1.044,3.7,3.7,0,0,1-1.024-2.706,4.074,4.074,0,0,1,1.1-2.978,3.93,3.93,0,0,1,2.942-1.124,4.281,4.281,0,0,1,1.806.36v1.582a2.73,2.73,0,0,0-1.667-.586,2.312,2.312,0,0,0-1.762.728,2.669,2.669,0,0,0-.687,1.908,2.54,2.54,0,0,0,.647,1.838,2.291,2.291,0,0,0,1.736.674,2.708,2.708,0,0,0,1.725-.652Z' fill='#fff')
                    path(d='M159.4,19.619a1.4,1.4,0,0,0-.884-.242,1.514,1.514,0,0,0-1.258.682,3.047,3.047,0,0,0-.5,1.852V25.5h-1.7V18h1.7v1.545h.029a2.6,2.6,0,0,1,.764-1.233,1.72,1.72,0,0,1,1.151-.444,1.425,1.425,0,0,1,.7.14Z' fill='#fff')
                    path(d='M163.788,25.676a3.71,3.71,0,0,1-2.767-1.051,3.8,3.8,0,0,1-1.035-2.787,3.7,3.7,0,0,1,3.985-4.014,3.581,3.581,0,0,1,2.733,1.033,3.994,3.994,0,0,1,.98,2.864,3.938,3.938,0,0,1-1.056,2.875A3.8,3.8,0,0,1,163.788,25.676Zm.08-6.5a1.932,1.932,0,0,0-1.571.7,2.913,2.913,0,0,0-.578,1.919,2.744,2.744,0,0,0,.585,1.856,1.957,1.957,0,0,0,1.564.678,1.862,1.862,0,0,0,1.539-.666,2.95,2.95,0,0,0,.537-1.9,2.99,2.99,0,0,0-.537-1.911A1.851,1.851,0,0,0,163.868,19.18Z' fill='#fff')
                    path(d='M168.94,25.266V23.691a3.383,3.383,0,0,0,2.1.725q1.535,0,1.535-.908a.714.714,0,0,0-.132-.436,1.263,1.263,0,0,0-.354-.318,2.864,2.864,0,0,0-.526-.25c-.2-.072-.428-.155-.677-.248a7.074,7.074,0,0,1-.829-.389,2.526,2.526,0,0,1-.615-.465,1.758,1.758,0,0,1-.369-.59,2.168,2.168,0,0,1-.124-.769,1.775,1.775,0,0,1,.256-.955,2.224,2.224,0,0,1,.687-.7,3.294,3.294,0,0,1,.979-.425A4.49,4.49,0,0,1,172,17.824a5.163,5.163,0,0,1,1.856.315v1.487a3.127,3.127,0,0,0-1.812-.542,2.323,2.323,0,0,0-.582.066,1.477,1.477,0,0,0-.442.183.893.893,0,0,0-.285.282.677.677,0,0,0-.1.363.779.779,0,0,0,.1.41.936.936,0,0,0,.3.3,2.675,2.675,0,0,0,.482.234q.282.105.648.23a9.5,9.5,0,0,1,.866.4,2.872,2.872,0,0,1,.654.465,1.789,1.789,0,0,1,.416.6,2.034,2.034,0,0,1,.147.81,1.855,1.855,0,0,1-.263,1,2.212,2.212,0,0,1-.7.7,3.28,3.28,0,0,1-1.013.413,5.2,5.2,0,0,1-1.209.136A5.1,5.1,0,0,1,168.94,25.266Z' fill='#fff')
                    path(d='M179.183,25.676a3.711,3.711,0,0,1-2.768-1.051,3.8,3.8,0,0,1-1.034-2.787,3.7,3.7,0,0,1,3.984-4.014,3.585,3.585,0,0,1,2.734,1.033,3.993,3.993,0,0,1,.979,2.864,3.934,3.934,0,0,1-1.056,2.875A3.794,3.794,0,0,1,179.183,25.676Zm.08-6.5a1.934,1.934,0,0,0-1.572.7,2.919,2.919,0,0,0-.578,1.919,2.749,2.749,0,0,0,.585,1.856,1.959,1.959,0,0,0,1.565.678,1.864,1.864,0,0,0,1.539-.666,2.956,2.956,0,0,0,.537-1.9,3,3,0,0,0-.537-1.911A1.852,1.852,0,0,0,179.263,19.18Z' fill='#fff')
                    path(d='M188.787,15.781a1.523,1.523,0,0,0-.782-.2q-1.235,0-1.235,1.4V18h1.74v1.333h-1.733V25.5h-1.7V19.333H183.8V18h1.279V16.784a2.37,2.37,0,0,1,.775-1.871,2.817,2.817,0,0,1,1.937-.684,2.866,2.866,0,0,1,.994.138Z' fill='#fff')
                    path(d='M193.94,25.42a2.944,2.944,0,0,1-1.307.248q-2.179,0-2.179-2.094V19.333H189.2V18h1.25V16.264l1.7-.483V18h1.79v1.333h-1.79v3.75a1.472,1.472,0,0,0,.242.952,1,1,0,0,0,.8.285,1.162,1.162,0,0,0,.745-.248Z' fill='#fff')
                    rect(x='13' y='11' width='9' height='9' fill='#f25022')
                    rect(x='13' y='21' width='9' height='9' fill='#00a4ef')
                    rect(x='23' y='11' width='9' height='9' fill='#7fba00')
                    rect(x='23' y='21' width='9' height='9' fill='#ffb900')
            .mt-3.text-center(v-if='login_enabled.password')
              a.text-secondary(href='#') Forgot username or password?
</template>


<script>


import "@/scss/login.scss";
import LogoWithoutText from "@/components/loader/LogoWithoutText.vue";
import Hourglass from "@/components/loader/hourglass.vue";
import anime from 'animejs/lib/anime.es.js';
import Cookies from 'js-cookie'

export default {
  name: 'Login',
  components:{
    LogoWithoutText,
    Hourglass
  },
  data() {
    return {
      login_data: {
        username: '',
        password: '',
      },
      loading: false
    }
  },
  mounted(){
    Cookies.remove('ct_rd');
    Cookies.set('ct_rd', location.href)


    const mainLoginPageAnim = anime.timeline({loop: false})


    if(!this.$root.inMobile) {
          mainLoginPageAnim.add({
        targets: '.login-card',
        translateY: [80,0],
        opacity: [0,1],
        duration: 500,
        easing: 'easeOutQuad'
      })
    }


    mainLoginPageAnim.add({
        targets: '.logo-text',
        translateY: [-10,0],
        opacity: [0,1],
        duration: 500,
        easing: 'easeOutQuad'
      }).add({
        targets: '.ct-top',
        translateY: [10,0],
        opacity: [0,1],
        easing: 'easeOutQuad',
        delay: 200,
        duration: 500
      }).add({
        targets: '.ct-bottom',
        translateY: [10,0],
        opacity: [0,1],
        easing: 'easeOutQuad',
        delay: 200,
        duration: 500
      }, "-=600").add({
        targets: '.ct-tq',
        translateX: [20,0],
        opacity: [0,1],
        easing: 'easeOutQuad',
        delay: 200,
        duration: 500
      },)
      .add({
        targets: '.login-group-1',
        translateX: [20,0],
        opacity: [0,1],
        easing: "easeOutQuad",
        duration: 200,
        delay: (el, i) => 500 + 30 * i
      }, "-=600")
      .add({
        targets: '.login-group-2',
        translateX: [20,0],
        opacity: [0,1],
        easing: "easeOutQuad",
        duration: 200,
        delay: (el, i) => 500 + 30 * i
      }, "-=600")
      .add({
        targets: '.login-group-3',
        translateX: [20,0],
        opacity: [0,1],
        easing: "easeOutQuad",
        duration: 200,
        delay: (el, i) => 500 + 30 * i
      }, "-=600")
      .add({
        targets: '.login-group-4',
        translateX: [20,0],
        opacity: [0,1],
        easing: "easeOutQuad",
        duration: 200,
        delay: (el, i) => 500 + 30 * i
      }, "-=600")
  },
  computed:{
      app_type(){
          return process.env.VUE_APP_TYPE;
      },
      login_enabled(){
          return this.$store.state.login_enabled;
      },
      isTestEnv() {
        return process.env.VUE_APP_IS_DEV
      },
      appName() {
        return process.env.VUE_APP_NAME ? process.env.VUE_APP_NAME : 'Certainty';
      }

  }

  
}
</script>
<style lang="scss">
.navbar-logo{
  height:30px;
  
  & .st0 {
    stroke-width: 35px;
  }
}

.logo-text {
  height: 6rem;
}

.certainty-text{
  text-transform: uppercase;

  &.ct-top {
    letter-spacing: 10px;
    margin-right: -10px;
    margin-bottom: 5px;
  }

  &.ct-bottom {
    text-align: center;
    letter-spacing: 2px;
  }
}

.navbar a {
  color: $primarytext;
}



.login-card {
  max-width: 450px;
}

</style>
