<template lang="pug">
  div
    div.d-flex.align-items-center.justify-content-between
      h3.m-0(v-if="tabIndex === 0") Overview
      h3.m-0(v-if="tabIndex === 1") Appointment List
      h3.m-0(v-if="tabIndex === 2") Calendar
      div.d-flex.align-items-center.justify-content-end
        div View:
        b-button.ml-1(@click="tabIndex=0;" :variant="tabIndex === 0 ? 'secondary' : 'outline-secondary'" size="sm" title="Overview")
          b-icon(icon="grid")
        b-button.ml-1(@click="tabIndex=1;" :variant="tabIndex === 1 ? 'secondary' : 'outline-secondary'"  size="sm" title="List")
          b-icon(icon="list-ul")
        b-button.ml-1(@click="tabIndex=2;" :variant="tabIndex === 2 ? 'secondary' : 'outline-secondary'"  size="sm" title="Calendar")
          b-icon(icon="calendar3")
    hr.mt-1
    b-tabs(v-model="tabIndex" lazy no-key-nav nav-class="hide-item")
      b-tab(title="Appointment")
        AppointmentOverview
        b-button.mt-4(@click="tabIndex=1;" variant="secondary" size="lg" block) View all appointments
      b-tab(title="List")
        b-card.shadow
          AppointmentList
      b-tab(title="Calendar")
        CalendarComponent
</template>
<script>
import AppointmentOverview from '@/components/appointments/overview.vue';
import CalendarComponent from '@/components/appointments/calendar.vue';
import AppointmentList from '@/components/appointments/list.vue';

export default {
  name: 'Appointments',
  certaintyMenu(link){
    return {iconType: 'fas', icon: 'calendar', link, description: 'Appointments', order:2}
  },
  components: {
    AppointmentOverview,
    CalendarComponent,
    AppointmentList
  },
  data() {
    return {
      tabIndex: 0,
    }
  },
  computed: {
    
  },
  created() {
    if(this.$store.state.appointment_default_view) {
      switch (this.$store.state.appointment_default_view) {
        case 'list':
          this.tabIndex = 1;
          break;
        case 'calendar':
          this.tabIndex = 2;
          break;
        case 'overview':
        default:
          this.tabIndex = 0;
          break;
      }
    }

    if(this.$route.query.view) {
      switch (this.$route.query.view) {
        case 'list':
          this.tabIndex = 1;
          break;
        case 'calendar':
          this.tabIndex = 2;
          break;
        case 'overview':
        default:
          this.tabIndex = 0;
          break;
      }
    }
  },
  mounted() {
    
  },
  methods: {
    
  }
}
</script>
<style lang="scss">

</style>