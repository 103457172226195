<template lang="pug">
  div
    div.d-flex.justify-content-between.align-items-end.flex-wrap.mb-3
        b-button(to="/appointments/create")
            b-icon(icon="plus")
            | New Appointment
        b-form(inline)
            b-form.ml-3(inline)
                label.mr-1 List:
                b-form-select(v-model="listBy" :options="listByOptions" @change="updateTable()" style="max-width:10em")
            b-form.ml-3(inline)
                label.mr-1 Filter by:
                b-form-select(v-model="filterBy" @change="updateTable()")
                    b-form-select-option(:value="0") None
                    b-form-select-option(:value="1") Month
                    b-form-select-option(:value="2") Date

                b-form-input(v-if="filterBy === 1" type="month" pattern="[0-9]{4}-[0-9]{2}" v-model="filterByMonth" @change="updateTable()")
                b-form-datepicker(v-if="filterBy === 2" v-model="filterByDate" @input="updateTable()")
    
    //div.d-flex.justify-content-center.align-items-center(v-if="loading" style="min-height:10em")
    //    b-spinner
    //div(v-else)
    b-overlay(:show="loadingTable" variant="white")
        b-table(:items="fetchedAppointments" :fields="fields" ref="table" :per-page="perPage" :current-page="currentPage" sort-by="start" sort-desc show-empty stacked="sm")
            template(#cell(subject)="data")
                div
                    b-badge.mr-1(v-if="data.item.cancelled" variant="danger") Cancelled
                    b-badge.mr-1(v-if="data.item.no_show" variant="warning") No-show
                | {{data.item.subject}}              
            template(#cell(start)="data")
                div.d-flex.align-items-center
                    b-icon.mr-1(icon="calendar" title="Date")
                    | {{ dayjs(data.item.start).isSame(data.item.end, 'day') ? dayjs(data.item.start).format('DD/MM/YYYY') : dayjs(data.item.start).format('DD/MM/YYYY') + ' to ' + dayjs(data.item.end).format('DD/MM/YYYY') }}
            template(#cell(time)="data")
                | {{ dayjs(data.item.start).isSame(data.item.end, 'day') ? dayjs(data.item.start).format('h:mm A') + ' to ' + dayjs(data.item.end).format('h:mm A') : dayjs(data.item.start).format('h:mm A') + ' to ' + dayjs(data.item.end).format('DD/MM/YYYY [at] h:mm A') }}
            template(#cell(location)="data")
                div
                    div.text-nowrap(v-if="data.item.location" title="Location")
                        b-icon.mr-1.text-secondary(icon="geo-alt" title="Location")
                        | {{data.item.location}}
                    div.text-nowrap(v-if="$store.state.roomlist_mode && getPlace(data.item.room_id)")
                        b-icon.mr-1.text-secondary(icon="building")
                        | {{ getPlace(data.item.room_id).roomListInfo.name }}
            template(#cell(clients)="data")
                span.text-muted.font-italic(v-if="data.item.clients.length <= 0") No client specified
                b-badge.border.mr-1.mb-1.p-1(v-for="client in data.item.clients" :key="client.id" :variant="client.declined ? 'danger' : 'light'") {{ client.name }}
            template(#cell(actions)="data")
                b-button.mr-1.mb-1(:to="'/appointments/'+(data.item.id)" size="sm")
                    b-icon.mr-1(icon="eye")
                    | View
                b-button.mr-1.mb-1(:to="'/appointments/create?duplicate='+(data.item.id)" variant="outline-secondary" size="sm")
                    b-icon.mr-1(icon="files")
                    | Duplicate
                b-button.mr-1.mb-1(v-if="data.item.user_id===user.user_id" :to="'/appointments/'+(data.item.id)+'/edit'" variant="outline-secondary" size="sm")
                    b-icon.mr-1(icon="pencil-square")
                    | Edit
        
        b-pagination.m-0(
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
        )
</template>

<script>
import dayjs from 'dayjs';

export default {
    name: 'ListAppointment',
    data() {
        return {
            loading: false,
            loadingTable: true,
            totalRows: 0,
            currentPage: 1,
            perPage: 20,
            listBy: 1,
            filterBy: 0,
            filterByMonth: dayjs().format('YYYY-MM'),
            filterByDate: dayjs().format('YYYY-MM-DD'),
            fields:[
                { key: 'subject', name: 'Subject', sortable: true },
                { key: 'start', name: 'Date', label: 'Date', sortable: true },
                { key: 'time', name: 'Time', label: 'Time', sortable: false },
                { key: 'location', name: 'Location', sortable: false },
                { key: 'organiser.graph_display_name', name: 'Organiser', label: 'Organiser', sortable: false },
                { key: 'clients', name: 'Clients', sortable: false },
                'actions'
            ]
        }
    },
    computed: {
        user(){
            return this.$store.state.user;
        },
        places(){
            return this.$store.state.places;
        },
        listByOptions() {
            let output = [
                { value: 0, text: 'All' },
                { value: 1, text: 'My' }
            ];

            let userOwnedGroups = this.$store.state.group_view_all_appointment ? this.user.user_groups_w_details : this.user.user_owned_groups_w_details;
            if(userOwnedGroups && userOwnedGroups.length) {
                let allowedGroups = userOwnedGroups;
                let mainGroup = this.$store.state.group_main_types;
                let supervisorGroup = this.$store.state.group_supervisor_types;
                let allowedGroupTypes = [];

                if(supervisorGroup && supervisorGroup.length > 0) {
                  let groups = userOwnedGroups.filter(group=>supervisorGroup.includes(group.group_type) && group.owner);
                  if(groups.length) {
                    output.push({ value: 2, text: 'Supervisee' })
                  }
                }

                if(mainGroup && mainGroup.length > 0) { allowedGroupTypes.push(...mainGroup); }

                if(allowedGroupTypes.length) {
                    allowedGroups = userOwnedGroups.filter(group=>allowedGroupTypes.includes(group.group_type));
                }

                if(allowedGroups.length) {
                    output.push({
                        label: "Groups / Teams",
                        options: allowedGroups.map(grp=>{ return {value:{type:"group", id:grp.id},text:grp.group_name} })
                    });
                }
            }

            return output;
        },
        filterStartDateTime() {
            // filter by month
            if(this.filterBy === 1) {
                return this.filterByMonth ? this.filterByMonth+"-01T00:00:00" : null;
            }
            // filter by date
            if(this.filterBy === 2) {
                return this.filterByDate ? this.filterByDate+"T00:00:00" : null;
            }

            return null;
        },
        filterEndDateTime() {
            // filter by month
            if(this.filterBy === 1) {
                if(!this.filterByMonth) { return null; }
                let addMonth = dayjs(this.filterByMonth+"-01T23:59:00").add(1, 'month');
                return addMonth.subtract(1, 'day').format("YYYY-MM-DDTHH:mm:ss"); // return last day of the month
            }
            // filter by date
            if(this.filterBy === 2) {
                return this.filterByDate ? this.filterByDate+"T23:59:00" : null;
            }

            return null;
        },
    },
    async mounted() {
        this.loading = true;
        await this.fetchPlaces();
        this.loading = false;
    },
    methods: {
        dayjs,
        updateTable() {
            if(this.loading) { return; }
            this.$refs.table.refresh();
            this.currentPage = 1;
        },
        async fetchedAppointments(ctx) {
            this.loadingTable = true;

            // fetchGroupAppointments - fetch all appointment including groups members appt
            // fetchMyAppointments - fetch all logged in user appointment, exclude groups members appt
            let action = (this.listBy===1) ? "fetchMyAppointments" : "fetchGroupAppointments";

            // prepare url params
            let params = {
                top: ctx.perPage,
                skip: ctx.perPage*(ctx.currentPage-1),
                orderby: ctx.sortBy,
                order: ctx.sortDesc ? 'desc' : 'asc',
            };
            // list by supervisor group
            if(this.listBy === 2) {
              params = { ...params, grouptype: 'supervisor' }
            }
            // filter by date/month
            if(this.filterStartDateTime && this.filterEndDateTime) {
                params = {
                    ...params,
                    start: this.filterStartDateTime,
                    end: this.filterEndDateTime
                }
            }
            // filter by group
            if(this.listBy?.type == 'group') {
                params = { ...params, group_id: this.listBy.id }
            }

            // request
            let items = await this.$store.dispatch(action,params).then((result)=>{
                this.totalRows = result.count;
                return result.value;
            }).catch(()=>{
                return [];
            });

            this.loadingTable = false;

            return items;
        },
        async fetchPlaces() {
            await this.$store.dispatch('fetchAllPlaces');
        },
        getPlace(roomId) {
            if(!this.places) { return null }
            return this.places.find(room => room.id === roomId);
        }
    }
}
</script>

<style>

</style>