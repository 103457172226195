<template lang="pug">
  .about
    h2 Clients
      
    b-nav(tabs)
      b-nav-item(to="/clients" :active="true") Clients
      b-nav-item(to="/client-group") {{$.clientGroup}}
      
    b-card.shadow.border-top-0.rounded-0
      ClientList
</template>
<script>
import ClientList from '@/components/clients/list.vue';
import ClientGroup from '@/components/client_group/list.vue';

export default {
  name: 'Clients',
  certaintyMenu(link){
    return {iconType: 'fas', icon: 'users', link, description: 'Clients', order:1}
  },
  components: {
    ClientList,
    ClientGroup
  },
  data(){
    return{
      activeTab: 'clients'
    }
  },
  computed: {
    clients(){
      return this.$store.state.clients;
    },
    localisation(){
      return this.$store.state.localisation;
    },
    $(){
      let output ={
        "clientGroup": "Client Group",
      };
              
      Object.assign(output,this.localisation);
      return output;
    },
  },
  mounted() {
    if(this.$route.query.tab == 'client-group') {
      this.activeTab = 'client-group';
    }
    else {
      this.activeTab = 'clients';
    }
    
  },
  methods: {
  }
}
</script>